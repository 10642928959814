import React from "react";

export default function PageTest() {
	return (
		<div class="text-center w-300">
			<p class="border-b">MA. LOUISSA F. SINADJAN, RPm</p>
			<p class="mt-0 border">Guidance Counselor</p>
			<p class="mt-0 border">BAP, ETP</p>
		</div>
	);
}
