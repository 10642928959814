import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Button, Col, notification, Popconfirm, Flex } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import { GET, POST } from "../../../providers/useAxiosQuery";
import {
    TableGlobalSearchAnimated,
    TablePageSize,
    TablePagination,
    TableShowingEntries,
    useTableScrollOnTop,
} from "../../../providers/CustomTableFilter";
import notificationErrors from "../../../providers/notificationErrors";
import TableFailedSentEmails from "./components/TableFailedSentEmails";

export default function PageFailedSentEmails() {
    const location = useLocation();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [tableFilter, setTableFilter] = useState({
        page: 1,
        page_size: 50,
        search: "",
        sort_order: "asc",
        sort_field: "failed_at_format",
    });

    const { data: dataSource, refetch: refetchSource } = GET(
        `api/failed_jobs?${new URLSearchParams(tableFilter)}`,
        "failed_jobs_list"
    );

    useEffect(() => {
        refetchSource();

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableFilter]);

    const onChangeTable = (pagination, filters, sorter) => {
        setTableFilter((prevState) => ({
            ...prevState,
            sort_field: sorter.columnKey,
            sort_order: sorter.order ? sorter.order.replace("end", "") : null,
            page: 1,
            page_size: "50",
        }));
    };

    const { mutate: mutateDeleteJobFailed, isLoading: isLoadingDeleteFailed } =
        POST(`api/failed_jobs_delete`, "failed_jobs_list");

    const handleSelectedArchived = (status) => {
        let data = {
            ids: selectedRowKeys,
        };
        mutateDeleteJobFailed(data, {
            onSuccess: (res) => {
                if (res.success) {
                    notification.success({
                        message: "Failed Sent Emails",
                        description: res.message,
                    });
                } else {
                    notification.error({
                        message: "Failed Sent Emails",
                        description: res.message,
                    });
                }
            },
            onError: (err) => {
                notificationErrors(err);
            },
        });
    };

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useTableScrollOnTop("tbl1", location);

    return (
        <Row gutter={[20, 20]} id="tbl_wrapper">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="tbl-top-filter first">
                    <Flex gap={15} className="flex-extra-filter">
                        <div></div>
                    </Flex>

                    <Flex gap={15} className="flex-page-size-wrapper">
                        <TablePageSize
                            tableFilter={tableFilter}
                            setTableFilter={setTableFilter}
                        />
                    </Flex>
                </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="tbl-top-filter second">
                    <Flex gap={15} align="center">
                        {width > 768 && (
                            <div>
                                {selectedRowKeys.length > 0 && (
                                    <Popconfirm
                                        title={
                                            <>
                                                Are you sure you want to
                                                <br />
                                                {"delete"} the selected{" "}
                                                {
                                                    (selectedRowKeys.length > 1
                                                        ? "Failed Sent Emails"
                                                        : "Failed Sent Email",
                                                    ",")
                                                }
                                                ?
                                            </>
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={() => {
                                            handleSelectedArchived();
                                        }}
                                    >
                                        <Button
                                            className="btn-main-secondary"
                                            name="btn_active_archive"
                                            loading={isLoadingDeleteFailed}
                                        >
                                            {"DELETE"} SELECTED
                                        </Button>
                                    </Popconfirm>
                                )}
                            </div>
                        )}
                    </Flex>

                    <Flex
                        gap={15}
                        align="center"
                        className="flex-pagination-wrapper"
                    >
                        <TableShowingEntries />

                        <TablePagination
                            tableFilter={tableFilter}
                            setTableFilter={setTableFilter}
                            setPaginationTotal={dataSource?.data.total}
                            showLessItems={true}
                            showSizeChanger={false}
                            tblIdWrapper="tbl_wrapper"
                        />
                    </Flex>
                </div>
            </Col>

            {width < 768 && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {selectedRowKeys.length > 0 && (
                        <Popconfirm
                            title={
                                <>
                                    Are you sure you want to
                                    <br />
                                    {"delete"} the selected{" "}
                                    {
                                        (selectedRowKeys.length > 1
                                            ? "Failed Sent Emails"
                                            : "Failed Sent Email",
                                        ",")
                                    }
                                    ?
                                </>
                            }
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                                handleSelectedArchived();
                            }}
                        >
                            <Button
                                className="btn-main-secondary"
                                name="btn_active_archive"
                                loading={isLoadingDeleteFailed}
                            >
                                {"DELETE"} SELECTED
                            </Button>
                        </Popconfirm>
                    )}
                </Col>
            )}

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TableFailedSentEmails
                    dataSource={dataSource}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    onChangeTable={onChangeTable}
                />
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="tbl-bottom-filter">
                    <TableShowingEntries />
                    <TablePagination
                        tableFilter={tableFilter}
                        setTableFilter={setTableFilter}
                        setPaginationTotal={dataSource?.data.total}
                        showLessItems={true}
                        showSizeChanger={false}
                        tblIdWrapper="tbl_wrapper"
                    />
                </div>
            </Col>
        </Row>
    );
}
