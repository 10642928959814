import React, { useEffect, useState } from "react";
import {
	Button,
	Col,
	Flex,
	notification,
	Popconfirm,
	Row,
	Select,
	Table,
	Tag,
	Tooltip,
} from "antd";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faFileSignature,
	faPaperPlane,
	faPencil,
	faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { GET, POST } from "../../../providers/useAxiosQuery";
import ModalFormDocument from "./components/ModalFormDocument";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../providers/CustomTableFilter";
import QuillOutputCollapse from "../../../providers/QuillOutputCollapse";
import notificationErrors from "../../../providers/notificationErrors";
import ModalFilePreview from "./components/ModalFilePreview";

export default function PageDocument() {
	const location = useLocation();
	dayjs.extend(relativeTime);

	const [dataUsers, setDataUsers] = useState([]);
	const [dataDepartments, setDataDepartments] = useState([]);
	const [dataDepartmentsUser, setDataDepartmentsUser] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [expandedRows, setExpandedRows] = useState({});

	const [toggleModalFormDocument, setToggleModalFormDocument] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFilePreview, setToggleModalFilePreview] = useState({
		open: false,
		data: null,
	});
	const toggleShowAll = (recordId) => {
		setExpandedRows((prev) => ({
			...prev,
			[recordId]: !prev[recordId],
		}));
	};

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 10,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		isTrash: 0,
		from: location.pathname,
		department_id: "",
	});

	const { data: dataDocument, refetch: refetchSourceDocument } = GET(
		`api/documents?${new URLSearchParams(tableFilter)}`,
		location.pathname.includes("sent")
			? "documents_sent_list"
			: "documents_received_list"
	);

	GET(
		`api/users`,
		"users_dropdown",
		(res) => {
			setDataUsers(res.data);
		},
		false
	);

	GET(
		`api/ref_department`,
		"departments_dropdown",
		(res) => {
			setDataDepartments(res.data);
		},
		false
	);

	GET(
		`api/profile_department`,
		"departments_user_dropdown",
		(res) => {
			setDataDepartmentsUser(res.data);
		},
		false
	);

	const {
		mutate: mutateDeleteRefDocument,
		isLoading: isLoadingDocument,
	} = POST(
		`api/multiple_archived_document`,
		location.pathname.includes("sent")
			? "documents_sent_list"
			: "documents_received_list"
	);

	const handleSelectedArchived = () => {
		let data = {
			ids: selectedRowKeys,
		};
		mutateDeleteRefDocument(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Document",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Document",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			sort_field: "created_at",
			sort_order: "desc",
			isTrash: 0,
			from: location.pathname,
			department_id: "",
		});

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		refetchSourceDocument();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper">
				{location.pathname.includes("sent") && (
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Button
							className="btn-main-primary btn-main-invert-outline b-r-none"
							icon={<FontAwesomeIcon icon={faPlus} />}
							name="btn_add"
							onClick={() =>
								setToggleModalFormDocument({
									open: true,
									data: null,
								})
							}
						>
							Send Document
						</Button>
					</Col>
				)}

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Flex justify="space-between" className="tbl-top-filter">
						<Flex gap={10} className="flex-extra-filter">
							<Button
								className={`btn-main-primary min-w-150 ${
									tableFilter.isTrash === 0 ? "active" : "outlined"
								}`}
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										isTrash: 0,
									}));
									setSelectedRowKeys([]);
								}}
							>
								Active
							</Button>

							<Button
								className={`btn-main-primary min-w-150 ${
									tableFilter.isTrash === 1 ? "active" : "outlined"
								}`}
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										isTrash: 1,
									}));
									setSelectedRowKeys([]);
								}}
							>
								Archived
							</Button>

							{selectedRowKeys.length > 0 && (
								<Popconfirm
									title={
										<>
											Are you sure you want to
											<br />
											{tableFilter.isTrash === 1 ? "activate" : "archive"} the
											selected <br />
											{selectedRowKeys.length > 1 ? "documents" : "document"}?
										</>
									}
									okText="Yes"
									cancelText="No"
									onConfirm={() => {
										handleSelectedArchived(
											tableFilter.isTrash === 1 ? "Active" : "Archived"
										);
									}}
								>
									<Button
										className="btn-main-secondary ml-10"
										name="btn_active_archive"
										loading={isLoadingDocument}
									>
										{tableFilter.isTrash === 1 ? "ACTIVE" : "ARCHIVED"} SELECTED
									</Button>
								</Popconfirm>
							)}

							<Select
								placeholder="Department"
								style={{ width: 300 }}
								allowClear
								showSearch
								filterOption={(input, option) => {
									// console.log("option", option);
									return (
										option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
									);
								}}
								onChange={(value) =>
									setTableFilter((ps) => ({
										...ps,
										department_id: value ? value : "",
									}))
								}
								options={
									dataDepartments && dataDepartments
										? dataDepartments.map((item) => ({
												label: item.department_name,
												value: item.id,
										  }))
										: []
								}
							/>

							<TableGlobalSearchAnimated
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</Flex>

						<Flex gap={10}>
							<TableShowingEntriesV2 />
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</Flex>
					</Flex>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Row gutter={[12, 12]}>
						<Col xs={24} sm={24} md={24}>
							<Table
								id="tblApplicant"
								className="ant-table-default ant-table-striped table-vertical-top"
								dataSource={dataDocument && dataDocument.data.data}
								rowKey={(record) => record.id}
								pagination={false}
								bordered={false}
								scroll={{ x: "max-content" }}
								sticky={{
									offsetHeader: 0,
								}}
								rowSelection={{
									selectedRowKeys,
									onChange: (selectedRowKeys) => {
										setSelectedRowKeys(selectedRowKeys);
									},
								}}
							>
								<Table.Column
									title="Action"
									key="action"
									dataIndex="action"
									align="center"
									width={150}
									render={(text, record) => {
										return (
											<>
												{location.pathname.includes("sent") && (
													<Tooltip title="Edit">
														<Button
															type="link"
															className="color-1 primary-color w-auto h-auto p-10"
															onClick={() =>
																setToggleModalFormDocument({
																	open: true,
																	data: record,
																})
															}
														>
															<FontAwesomeIcon icon={faPencil} />
														</Button>
													</Tooltip>
												)}

												<Tooltip title="View Attached File">
													<Button
														type="link"
														className="color-1 primary-color w-auto h-auto p-10"
														onClick={() =>
															setToggleModalFilePreview({
																open: true,
																data: record,
															})
														}
													>
														<FontAwesomeIcon icon={faEye} />
													</Button>
												</Tooltip>

												{location.pathname.includes("sent") && (
													<Tooltip title="Re-send">
														<Button
															type="link"
															className="color-1 primary-color w-auto h-auto p-10"
															onClick={() =>
																setToggleModalFilePreview({
																	open: false,
																	data: record,
																})
															}
														>
															<FontAwesomeIcon icon={faPaperPlane} />
														</Button>
													</Tooltip>
												)}

												{location.pathname.includes("received") && (
													<Tooltip title="Sign">
														<Button
															type="link"
															className="color-1 primary-color w-auto h-auto p-10"
															onClick={() =>
																setToggleModalFormDocument({
																	open: true,
																	data: record,
																})
															}
														>
															<FontAwesomeIcon icon={faFileSignature} />
														</Button>
													</Tooltip>
												)}
											</>
										);
									}}
								/>

								<Table.Column
									title="Title"
									key="title"
									dataIndex="title"
									sorter={(a, b) => a.title.localeCompare(b.title)}
									render={(_, record) => {
										let title = record.title;

										return <QuillOutputCollapse quillOutput={title} />;
									}}
									width={200}
								/>

								<Table.Column
									title="Subject"
									key="subject"
									dataIndex="subject"
									sorter={(a, b) => a.title.localeCompare(b.title)}
									render={(_, record) => {
										let subject = record.subject;

										return <QuillOutputCollapse quillOutput={subject} />;
									}}
									width={200}
								/>

								<Table.Column
									title="Email Body"
									key="email_body"
									dataIndex="email_body"
									render={(_, record) => {
										let email_body = record.email_body;

										return <QuillOutputCollapse quillOutput={email_body} />;
									}}
									width={200}
								/>

								{location.pathname.includes("sent") && (
									<Table.Column
										title="Date Sent"
										key="created_at"
										dataIndex="created_at"
										sorter={(a, b) =>
											dayjs(a.created_at).unix() - dayjs(b.created_at).unix()
										}
										render={(text, record) => {
											return (
												<>{dayjs(record.created_at).format("MMMM DD, YYYY")}</>
											);
										}}
										width={200}
									/>
								)}

								{location.pathname.includes("received") && (
									<>
										<Table.Column
											title="From"
											key="from_id"
											dataIndex="from_id"
											sorter={(a, b) => a.title.localeCompare(b.title)}
											width={200}
											render={(text, record) => {
												const from = record.user_from
													? record.user_from.email
													: "";

												return (
													<>
														<Tag style={{ fontSize: "14px" }}>{from}</Tag>
													</>
												);
											}}
										/>
									</>
								)}

								<Table.Column
									title="Received By"
									key="received_by_id"
									dataIndex="received_by_id"
									sorter={(a, b) => a.title.localeCompare(b.title)}
									width={200}
									render={(text, record) => {
										let recipients = [];

										if (record && record.received_by_id) {
											recipients = record.received_by_id.split(",");
										}

										return (
											<>
												{recipients.map((recipient_id, index) => (
													<Tag key={index} style={{ fontSize: "14px" }}>
														{recipient_id}
													</Tag>
												))}
											</>
										);
									}}
								/>

								{location.pathname.includes("sent") && (
									<>
										<Table.Column
											title="Signed By"
											key="signed_by_id"
											dataIndex="signed_by_id"
											sorter={(a, b) => a.title.localeCompare(b.title)}
											width={200}
										/>

										<Table.Column
											title="Date Signed"
											key="date_signed"
											dataIndex="date_signed"
											sorter={(a, b) => a.title.localeCompare(b.title)}
											render={(text, record) => {
												return (
													<>
														{record.date_signed
															? `${dayjs(record.date_signed).format(
																	"MMMM DD, YYYY"
															  )}`
															: ""}
													</>
												);
											}}
											width={200}
										/>

										<Table.Column
											title="Recipient"
											key="recipient_id"
											dataIndex="recipient_id"
											render={(text, record) => {
												const showAll = expandedRows[record.id] || false;

												let recipients = [];

												if (
													record &&
													record.document_recipients &&
													record.document_recipients.length > 0
												) {
													recipients = record.document_recipients.map(
														(item) => item.profile?.user?.email
													);
												}

												const displayedRecipients = showAll
													? recipients
													: recipients.slice(0, 3);

												return (
													<>
														{displayedRecipients.map((recipient_id, index) => (
															<Tag key={index} style={{ fontSize: "14px" }}>
																{recipient_id}
															</Tag>
														))}
														{recipients.length > 3 && (
															<Button
																type="link"
																onClick={() => toggleShowAll(record.id)}
															>
																{showAll ? "Show Less" : "Show More"}
															</Button>
														)}
													</>
												);
											}}
											width={200}
										/>
									</>
								)}

								<Table.Column
									title="Date Completion"
									key="date_completion"
									dataIndex="date_completion"
									sorter={(a, b) => a.title.localeCompare(b.title)}
									render={(text, record) => {
										return (
											<>
												{record.date_completion
													? `${dayjs(record.date_completion).format(
															"MMMM DD, YYYY"
													  )}`
													: ""}
											</>
										);
									}}
									width={200}
								/>
							</Table>
						</Col>

						<Col xs={24} sm={24} md={24}>
							<div className="tbl-bottom-filter">
								<TableShowingEntriesV2 />
								<TablePagination
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
									setPaginationTotal={dataDocument && dataDocument?.data?.total}
									showLessItems={true}
									showSizeChanger={false}
									tblIdWrapper="tbl_wrapper"
								/>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>

			<ModalFormDocument
				toggleModalFormDocument={toggleModalFormDocument}
				setToggleModalFormDocument={setToggleModalFormDocument}
				dataUsers={dataUsers}
				dataDepartments={dataDepartments}
				dataDepartmentsUser={dataDepartmentsUser}
			/>

			<ModalFilePreview
				toggleModalFilePreview={toggleModalFilePreview}
				setToggleModalFilePreview={setToggleModalFilePreview}
			/>
		</>
	);
}
