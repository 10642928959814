import { Table } from "antd";

export default function TableFailedSentEmails(props) {
	const {
		dataSource,
		selectedRowKeys,
		setSelectedRowKeys,
		onChangeTable,
	} = props;

	return (
		<Table
			id="tbl1"
			className="ant-table-default ant-table-striped table-vertical-top"
			dataSource={dataSource && dataSource.data.data}
			rowKey={(record) => record.id}
			pagination={false}
			bordered={false}
			onChange={onChangeTable}
			scroll={{ x: "max-content" }}
			sticky
			rowSelection={{
				selectedRowKeys,
				onChange: (selectedRowKeys) => {
					setSelectedRowKeys(selectedRowKeys);
				},
			}}
		>
			<Table.Column
				title="Date & Time"
				key="failed_at_format"
				dataIndex="failed_at_format"
				sorter={true}
				defaultSortOrder="ascend"
				width={150}
			/>
			<Table.Column
				title="UUID"
				key="uuid"
				dataIndex="uuid"
				sorter={true}
				width={150}
			/>
			<Table.Column
				title="Connection"
				key="connection"
				dataIndex="connection"
				sorter={true}
				width={250}
			/>
			<Table.Column
				title="Queue"
				key="queue"
				dataIndex="queue"
				sorter={true}
				width={250}
			/>
			<Table.Column
				title="Payload"
				key="payload"
				dataIndex="payload"
				sorter={true}
				width={250}
			/>
			<Table.Column
				title="Exception"
				key="exception"
				dataIndex="exception"
				sorter={true}
				width={150}
			/>
		</Table>
	);
}
