import { Typography } from "antd";
import { useState } from "react";

export default function QuillOutputCollapse(props) {
	const { quillOutput } = props;

	const [expanded, setExpanded] = useState(false);

	return (
		<Typography.Paragraph
			ellipsis={{
				rows: 2,
				expandable: "collapsible",
				expanded,
				onExpand: (_, info) => setExpanded(info.expanded),
				symbol: "more",
			}}
			style={{
				width: "100%",
			}}
		>
			<div
				className="quill-output"
				dangerouslySetInnerHTML={{ __html: quillOutput }}
			/>
		</Typography.Paragraph>
	);
}
