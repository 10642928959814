import { useEffect, useState } from "react";
import { Modal, Button, Form, notification, Upload } from "antd";

import { POST } from "../../../../../providers/useAxiosQuery";
import { apiUrl } from "../../../../../providers/companyInfo";
import validateRules from "../../../../../providers/validateRules";
import notificationErrors from "../../../../../providers/notificationErrors";
import FloatInput from "../../../../../providers/FloatInput";
import imageFileToBase64 from "../../../../../providers/imageFileToBase64";

export default function ModalForm(props) {
	const { toggleModalForm, setToggleModalForm } = props;

	const [form] = Form.useForm();

	const [imageUrl, setImageUrl] = useState(null);

	const { mutate: mutateDepartment, isLoading: isLoadingDepartment } = POST(
		`api/ref_department`,
		"ref_department_list"
	);

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = new FormData();

		data.append("department_name", values.department_name);

		mutateDepartment(data, {
			onSuccess: (res) => {
				if (res.success) {
					setToggleModalForm({
						open: false,
						data: null,
					});
					form.resetFields();
					notification.success({
						message: "Department",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Department",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		if (toggleModalForm.open) {
			if (toggleModalForm.data) {
				form.setFieldsValue({
					...toggleModalForm.data,
				});

				if (toggleModalForm.data.attachment) {
					setImageUrl(apiUrl(toggleModalForm.data.attachment));
				}
			} else {
				form.resetFields();
				setImageUrl(null);
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalForm]);

	return (
		<Modal
			wrapClassName="modal-form"
			title="Form Department"
			open={toggleModalForm.open}
			onCancel={() => {
				setToggleModalForm({
					open: false,
					data: null,
				});
				form.resetFields();
				setImageUrl(null);
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalForm({
							open: false,
							data: null,
						});
						form.resetFields();
						setImageUrl(null);
					}}
					disabled={isLoadingDepartment}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					key={2}
					onClick={() => form.submit()}
					loading={isLoadingDepartment}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="department_name" rules={[validateRules.required]}>
					<FloatInput
						label="Department Name"
						placeholder="Department Name"
						required
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
