import { useEffect, useState } from "react";
import {
	Row,
	Button,
	Col,
	Table,
	notification,
	Popconfirm,
	Image,
	Flex,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import { apiUrl } from "../../../../providers/companyInfo";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntries,
	TableShowingEntriesV2,
} from "../../../../providers/CustomTableFilter";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalForm from "./components/ModalForm";

export default function PageDepartment() {
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [toggleModalForm, setToggleModalForm] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_order: "asc",
		sort_field: "department_name",
		status: "Active",
		isTrash: 0,
	});

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/ref_department?${new URLSearchParams(tableFilter)}`,
		"ref_department_list"
	);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
			isTrash: 0,
		}));
	};

	useEffect(() => {
		if (dataSource) {
			refetchSource();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const {
		mutate: mutateDeleteDepartment,
		loading: loadingDeleteDepartment,
	} = POST(`api/multiple_archived_department`, "ref_department_list");

	const handleSelectedArchived = (status) => {
		let data = {
			status: status === "Active" ? "Archived" : "Active",
			ids: selectedRowKeys,
		};
		mutateDeleteDepartment(data, {
			onSuccess: (res) => {
				console.log("res", res);
				if (res.success) {
					notification.success({
						message: "Department",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Department",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Button
					className="btn-main-primary btn-main-invert-outline b-r-none"
					icon={<FontAwesomeIcon icon={faPlus} />}
					onClick={() =>
						setToggleModalForm({
							open: true,
							data: null,
						})
					}
				>
					Add Department
				</Button>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={16}>
				<Row gutter={[12, 12]}>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<div className="tbl-top-filter">
							<Flex gap={10}>
								<Button
									className={`btn-main-primary min-w-150 ${
										tableFilter.isTrash === 0 ? "active" : "outlined"
									}`}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											isTrash: 0,
										}));
										setSelectedRowKeys([]);
									}}
								>
									Active
								</Button>

								<Button
									className={`btn-main-primary min-w-150 ${
										tableFilter.isTrash === 1 ? "active" : "outlined"
									}`}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											isTrash: 1,
										}));
										setSelectedRowKeys([]);
									}}
								>
									Archived
								</Button>

								{selectedRowKeys.length > 0 && (
									<Popconfirm
										title={
											<>
												Are you sure you want to
												<br />
												{tableFilter.isTrash === 1 ? "activate" : "archive"} the
												selected <br />
												{selectedRowKeys.length > 1
													? "departments"
													: "department"}
												?
											</>
										}
										okText="Yes"
										cancelText="No"
										onConfirm={() => {
											handleSelectedArchived(
												tableFilter.isTrash === 1 ? "Active" : "Archived"
											);
										}}
									>
										<Button
											className="btn-main-secondary ml-10"
											name="btn_active_archive"
											loading={loadingDeleteDepartment}
										>
											{tableFilter.isTrash === 1 ? "ACTIVE" : "ARCHIVED"}{" "}
											SELECTED
										</Button>
									</Popconfirm>
								)}

								<TableGlobalSearchAnimated
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
								/>
							</Flex>

							<Flex gap={10}>
								<TableShowingEntriesV2 />
								<TablePageSize
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
								/>
							</Flex>
						</div>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Table
							className="ant-table-default ant-table-striped"
							dataSource={dataSource && dataSource.data.data}
							rowKey={(record) => record.id}
							pagination={false}
							bordered={false}
							onChange={onChangeTable}
							scroll={{ x: "max-content" }}
							rowSelection={{
								selectedRowKeys,
								onChange: (selectedRowKeys) => {
									setSelectedRowKeys(selectedRowKeys);
								},
							}}
						>
							<Table.Column
								title="Action"
								key="action"
								dataIndex="action"
								align="center"
								width={100}
								render={(text, record) => {
									return (
										<>
											<Button
												type="link"
												className="color-info w-auto h-auto p-0"
												onClick={() => {
													setToggleModalForm({
														open: true,
														data: record,
													});
												}}
											>
												<FontAwesomeIcon icon={faPencil} />
											</Button>
										</>
									);
								}}
							/>

							<Table.Column
								title="Department Name"
								key="department_name"
								dataIndex="department_name"
								sorter={true}
								defaultSortOrder="ascend"
								width={500}
							/>
						</Table>
					</Col>

					<Col xs={24} sm={24} md={24}>
						<div className="tbl-bottom-filter">
							<TableShowingEntries />
							<TablePagination
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
								setPaginationTotal={dataSource?.data.total}
								showLessItems={true}
								showSizeChanger={false}
								tblIdWrapper="tbl_wrapper"
							/>
						</div>
					</Col>
				</Row>
			</Col>

			<ModalForm
				toggleModalForm={toggleModalForm}
				setToggleModalForm={setToggleModalForm}
			/>
		</Row>
	);
}
