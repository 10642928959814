import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import {
	faHome,
	faUsers,
	faEnvelopes,
	faCogs,
	faPaperPlane,
	faInboxIn,
	faCog,
} from "@fortawesome/pro-regular-svg-icons";

import dayjs from "dayjs";
import { GET } from "../providers/useAxiosQuery";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import PageRequestPermission from "../views/errors/PageRequestPermission";
import Page404 from "../views/errors/Page404";

import PageLogin from "../views/public/PageLogin/PageLogin";
import PageRegister from "../views/public/PageRegister/PageRegister";

import PageEditProfile from "../views/private/PageEditProfile/PageEditProfile";
import PageDashboard from "../views/private/PageDashboard/PageDashboard";

import PageTest from "../views/private/PageTest";
import PageReport from "../views/private/PageReport/PageRecipient";
import PageFailedSentEmails from "../views/private/PageFailedSentEmails/PageFailedSentEmails";
import PageDepartment from "../views/private/PageSystemSettings/PageDepartment/PageDepartment";
import PageDocument from "../views/private/PageDocument/PageDocument";

export default function RouteList() {
	const location = useLocation();

	// From TableExamSchedule
	const newPath = location.pathname.split("/")[3];

	const { data: dataExamSchedule } = GET(
		`api/ref_exam_schedule`,
		"exam_schedule_list",
		(res) => {},
		false
	);

	// console.log("dataExamSchedule: ", dataExamSchedule);

	let examDate = "";
	let examStart = "";
	let examEnd = "";

	if (
		dataExamSchedule &&
		dataExamSchedule.data &&
		dataExamSchedule.data.length > 0
	) {
		const matchingItem = dataExamSchedule.data.find(
			(item) => item.id === Number(newPath)
		);

		if (matchingItem) {
			examDate = matchingItem.exam_date;
			examStart = matchingItem.time_in + " " + matchingItem.time_in_meridiem;
			examEnd = matchingItem.time_out + " " + matchingItem.time_out_meridiem;
		}
	}

	let formattedDate = dayjs(examDate).format("MMMM DD, YYYY");

	// console.log("dataExamSchedule RouteList:", dataExamSchedule);
	// console.log("newPath :", newPath);
	// console.log("examDate :", examDate);
	// console.log("examStart :", examStart);
	// console.log("formattedDate :", formattedDate);

	return (
		<Routes>
			<Route
				path="/"
				element={
					<PublicRoute title="LOGIN" pageId="PageLogin" component={PageLogin} />
				}
			/>

			<Route
				path="/registration"
				element={
					<PublicRoute
						title="REGISTRATION"
						pageId="PageRegistration"
						component={PageRegister}
					/>
				}
			/>

			<Route
				path="/edit-profile"
				element={
					<PrivateRoute
						moduleName="Edit Profile"
						title="User"
						subtitle="VIEW / EDIT"
						pageId="PageUserProfile"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Edit Profile",
							},
						]}
						component={PageEditProfile}
					/>
				}
			/>

			<Route
				path="/dashboard"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Dashboard"
						title="Dashboard"
						subtitle="ADMIN"
						pageId="PageDashboard"
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Dashboard",
							},
						]}
						component={PageDashboard}
					/>
				}
			/>

			{/* start document */}
			<Route
				path="/document/sent"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Document"
						title="Sent"
						subtitle="ADMIN"
						pageId="PageSendDocument"
						pageHeaderIcon={faPaperPlane}
						breadcrumb={[
							{
								name: "Document",
							},
							{
								name: "Sent",
							},
						]}
						component={PageDocument}
					/>
				}
			/>

			<Route
				path="/document/received"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Document"
						title="Received"
						subtitle="ADMIN"
						pageId="PageSendDocument"
						pageHeaderIcon={faInboxIn}
						breadcrumb={[
							{
								name: "Document",
							},
							{
								name: "Received",
							},
						]}
						component={PageDocument}
					/>
				}
			/>
			{/* end document */}

			<Route
				path="/report"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Report"
						title="Report"
						subtitle="ADMIN"
						pageId="PageReport"
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Report",
							},
						]}
						component={PageReport}
					/>
				}
			/>

			{/* Setting */}
			<Route
				path="/system-settings/department"
				element={
					<PrivateRoute
						// moduleCode="M-04"
						moduleName="System Settings - Department"
						title="Settings"
						subtitle="DEPARTMENT"
						pageId="PageDepartment"
						pageHeaderIcon={faCog}
						breadcrumb={[
							{
								name: "System Settings",
							},
							{
								name: "Department",
							},
						]}
						component={PageDepartment}
					/>
				}
			/>

			<Route
				path="/failed-sent-emails"
				element={
					<PrivateRoute
						// moduleCode="M-04"
						moduleName="Failed Sent Emails"
						title="Sent Emails"
						subtitle="FAILED"
						pageId="PageFailedSentEmails"
						pageHeaderIcon={faCog}
						breadcrumb={[
							{
								name: "Failed Sent Emails",
							},
						]}
						component={PageFailedSentEmails}
					/>
				}
			/>

			{/* VOTING */}
			<Route
				path="/test"
				element={
					<PageTest
						moduleCode="M-12"
						moduleName="Email Template"
						title="Template"
						subtitle="EMAIL"
						pageId="PageEmailTemplate"
						pageClassName="email-template"
						pageHeaderIcon={faEnvelopes}
						breadcrumb={[
							{
								name: "Email Template",
							},
						]}
					/>
				}
			/>

			<Route path="/request-permission" element={<PageRequestPermission />} />
			<Route path="*" element={<Page404 />} />
		</Routes>
	);
}
