import React, { useState, useEffect } from "react";
import {
	Modal,
	Button,
	Form,
	Row,
	Col,
	Upload,
	notification,
	Input,
	Space,
	Divider,
	Image as AntdImage,
	Progress,
	Flex,
	TreeSelect,
	Checkbox,
} from "antd";
import { useLocation } from "react-router-dom";

import dayjs from "dayjs";
import { apiUrl } from "../../../../providers/companyInfo";
import { POST } from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrowser, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import FloatInput from "../../../../providers/FloatInput";
import FloatQuill from "../../../../providers/FloatQuill";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatTextArea from "../../../../providers/FloatTextArea";
import validateRules from "../../../../providers/validateRules";
import notificationErrors from "../../../../providers/notificationErrors";

const { SHOW_PARENT } = TreeSelect;

export default function ModalFormDocument(props) {
	const {
		toggleModalFormDocument,
		setToggleModalFormDocument,
		dataUsers,
		dataDepartments,
		dataDepartmentsUser,
	} = props;

	const location = useLocation();
	const [form] = Form.useForm();
	const [onProgress, setOnProgress] = useState(0);
	const [emailAttachment, setEmailAttachment] = useState([]);
	const [newType, setNewType] = useState("");
	const [selectedTreeData, setSelectedTreeData] = useState(["Individual"]); /// THIS

	const treeData = [
		{
			title: "All",
			value: "All",
			children: dataUsers
				? dataUsers
						.filter((user) => user.user_role_id != 1)
						.map((user) => ({
							title: user.email,
							value: user.id,
							disabled: selectedTreeData.includes(user.id),
						}))
						.sort((a, b) => a.title.localeCompare(b.title))
				: [],
		},
		{
			title: "Department",
			value: "Department",
			children: dataDepartments
				? dataDepartments.map((dept) => ({
						title: dept.department_name,
						value: dept.department_name,
						children: dataDepartmentsUser
							? dataDepartmentsUser
									.filter((dept_user) => dept_user.department_id === dept.id)
									.map((dept_user) => ({
										title: dept_user.profile?.user?.email,
										value: `${dept.id}-${dept_user.profile?.user?.id}`,
										// value: dept_user.profile?.user?.id,
										disabled: selectedTreeData.includes(
											dept_user.profile?.user?.id
										),
									}))
									.sort((a, b) => a.title.localeCompare(b.title))
							: [],
				  }))
				: [],
		},
	];

	const [currentType, setCurrentType] = useState([
		{ label: "For Signature", value: "For Signature" },
		{ label: "Memo", value: "Memo" },
	]);

	const handleAddType = () => {
		if (newType) {
			setCurrentType((prevOptions) => [
				...prevOptions,
				{ label: newType, value: newType },
			]);
			setNewType("");
		}
	};

	const { mutate: mutateDocument, isLoading: isLoadingUpdateDocument } = POST(
		location.pathname.includes("sent")
			? `api/document_sent`
			: `api/document_received`,
		location.pathname.includes("sent")
			? "documents_sent_list"
			: "documents_received_list",
		true,
		() => {},
		setOnProgress
	);

	const onFinish = (values) => {
		let departments = dataDepartments.reduce((acc, dept) => {
			acc.push(dept.department_name);
			return acc;
		}, []);

		let data = new FormData();

		if (toggleModalFormDocument.data && toggleModalFormDocument.data.id) {
			data.append("id", toggleModalFormDocument.data.id);
		}

		let recipients = [];

		if (location.pathname.includes("sent")) {
			let recipientFilter = values.recipient_id;

			if (recipientFilter.find((x) => x === "All")) {
				recipients = dataUsers.reduce((acc, curr) => {
					if (curr.id) {
						acc.push(curr.id);
					}
					return acc;
				}, []);
			} else if (recipientFilter.find((x) => x === "Department")) {
				recipients = dataDepartmentsUser.reduce((acc, curr) => {
					if (curr.id) {
						acc.push(curr.id);
					}
					return acc;
				}, []);
			} else if (
				recipientFilter.filter((x) => departments.includes(x)).length > 0
			) {
				dataDepartmentsUser.forEach((item) => {
					if (recipientFilter.includes(item.ref_derpartment.department_name)) {
						recipients.push(item.id);
					}
				});
			} else {
				recipients = recipientFilter.map((x) => x.split("-")[1]);
			}
		}

		if (
			location.pathname.includes("received") &&
			values.received_by_id &&
			values.from_id
		) {
			// data.append("received_by_id", values.received_by_id);
			data.append("from_id", values.from_id);
		}

		data.append(
			"date_completion",
			dayjs(values.date_completion).format("YYYY-MM-DD HH:mm:ss")
		);

		emailAttachment.forEach((item) => {
			if (item.originFileObj) {
				data.append("attachments[]", item.originFileObj);
			}
		});

		mutateDocument(data, {
			onSuccess: (res) => {
				if (res.success) {
					setToggleModalFormDocument({
						open: false,
						data: null,
					});
					form.resetFields();

					notification.success({
						message: "Document",
						description: res.message,
					});
					setOnProgress(0);
					setSelectedTreeData([]);
					setEmailAttachment([]);
				} else {
					notification.error({
						message: "Document",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const {
		mutate: mutateEventAttachment,
		isLoading: isLoadingECOMAttachment,
	} = POST(`api/ecom_attachment_delete`, "ecom_attachment_delete");

	const handleDeleteAttachment = (item, index) => {
		if (item.id) {
			mutateEventAttachment(item, {
				onSuccess: (res) => {
					if (res.success) {
						setEmailAttachment((prev) => prev.filter((_, i) => i !== index));
						notification.success({
							message: "Event Attachment",
							description: res.message,
						});
					} else {
						notification.error({
							message: "Event Attachment",
							description: res.message,
						});
					}
				},
				onError: (err) => {
					notificationErrors(err);
				},
			});
		} else {
			setEmailAttachment((prev) => prev.filter((_, i) => i !== index));
		}
	};

	useEffect(() => {
		if (toggleModalFormDocument.open) {
			if (
				toggleModalFormDocument &&
				toggleModalFormDocument.data &&
				toggleModalFormDocument.data.id
			) {
				let recipients = [];
				if (location.pathname.includes("sent")) {
					if (
						toggleModalFormDocument.data.document_recipients &&
						toggleModalFormDocument.data.document_recipients.length > 0
					) {
						recipients = toggleModalFormDocument.data.document_recipients.map(
							(item) => item.recipient_id
						);
					}
				}

				// let receivedBy = [];
				// if (toggleModalFormDocument.data.received_by_id) {
				// 	receivedBy = toggleModalFormDocument.data.received_by_id.split(",");
				// }

				let attachments = [];
				if (
					toggleModalFormDocument.data.attachments &&
					toggleModalFormDocument.data.attachments.length > 0
				) {
					let attachmentEmails = toggleModalFormDocument.data.attachments.filter(
						(item) =>
							item.file_description === "Sent Document" ||
							item.file_description === "Received Document"
					);

					attachments = attachmentEmails.map((item) => {
						return {
							id: item.id,
							uid: item.id,
							name: item.file_name,
							src: apiUrl(item.file_path),
						};
					});
				}

				let from = "";
				if (toggleModalFormDocument.data.user_form) {
					from = toggleModalFormDocument.data.user_form.email;
				}

				form.setFieldsValue({
					...toggleModalFormDocument.data,
					date_completion: toggleModalFormDocument.data.date_completion
						? dayjs(toggleModalFormDocument.data.date_completion)
						: null,
					recipient_id: recipients,
					// received_by_id: receivedBy,
					fileList: attachments,
					from_id: from,
				});
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormDocument]);

	const onChange = (newValue) => {
		setSelectedTreeData(newValue);
	};

	return (
		<Modal
			title={location.pathname.includes("sent") ? "Send File" : "Receive File"}
			open={toggleModalFormDocument.open}
			onCancel={() => {
				setToggleModalFormDocument({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalFormDocument({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					key={2}
					onClick={(values) => form.submit(values)}
					loading={isLoadingUpdateDocument}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish} initialValues={{ note: "N/A" }}>
				<Row gutter={[12, 0]}>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="type" rules={[validateRules.required]}>
							<FloatSelect
								label="Type"
								placeholder="Type"
								options={currentType}
								dropdownRender={(menu) => (
									<>
										{menu}
										<Divider
											style={{
												margin: "8px 0",
											}}
										/>
										<Space
											style={{
												padding: "0 8px 4px",
											}}
										>
											<Input
												style={{
													border: "1px solid",
												}}
												value={newType}
												onChange={(e) => setNewType(e.target.value)}
											/>
											<Button
												className="btn-main-primary"
												type="text"
												icon={<FontAwesomeIcon icon={faPlus} />}
												name="btn-add-type"
												onClick={handleAddType}
											>
												Add Type
											</Button>
										</Space>
									</>
								)}
								disabled={location.pathname.includes("received")}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="title" rules={[validateRules.required]}>
							<FloatInput
								label="Title"
								placeholder="Title"
								required
								disabled={location.pathname.includes("received")}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="subject">
							<FloatInput
								label="Email Subject"
								placeholder="Email Subject"
								disabled={location.pathname.includes("received")}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="email_body">
							<FloatQuill
								label="Email Body"
								placeholder="Email Body"
								disabled={location.pathname.includes("received")}
							/>
						</Form.Item>
					</Col>

					{location.pathname.includes("sent") ? (
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Form.Item name="recipient_id" rules={[validateRules.required]}>
								<TreeSelect
									value={selectedTreeData}
									onChange={onChange}
									treeData={treeData}
									treeCheckable
									treeLine
									showCheckedStrategy={SHOW_PARENT}
									placeholder="Recipient"
									popupMatchSelectWidth
									allowClear
									notFoundContent="No user found"
								/>
							</Form.Item>
						</Col>
					) : (
						<>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Form.Item name="from_id">
									<FloatInput label="From" placeholder="From" disabled />
								</Form.Item>
							</Col>

							{/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Form.Item
									name="received_by_id"
									rules={[validateRules.required]}
								>
									<TreeSelect
										value={selectedTreeData}
										onChange={onChange}
										treeData={treeData}
										treeCheckable
										treeLine
										showCheckedStrategy={SHOW_PARENT}
										placeholder="Received By"
										popupMatchSelectWidth
										allowClear
										notFoundContent="No user found"
									/>
								</Form.Item>
							</Col> */}
						</>
					)}

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Form.Item name="date_completion" rules={[validateRules.required]}>
							<FloatDatePicker
								className="dateCompletion"
								label="Date Completion"
								placeholder="Date Completion"
								required={true}
								allowClear
								format={{
									format: "MM-DD-YYYY",
									type: "mask",
								}}
								disabled={location.pathname.includes("received")}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Upload.Dragger
							action={false}
							multiple={true}
							showUploadList={false}
							className="event-email-attachment-upload"
							listType="picture-card"
							accept="application/pdf"
							maxCount={3}
							fileList={emailAttachment}
							onChange={({ fileList: newFileList }) => {
								const newFileListPromises = newFileList.map((item) => {
									return new Promise((resolve, reject) => {
										const file = item.originFileObj;
										const isPDF = file.type === "application/pdf";
										const isLt5M = file.size / 1024 / 1024 < 5;

										if (!isPDF) {
											notification.error({
												message: file.name,
												description: `${file.name} is not a PDF file. Please upload a valid PDF file.`,
											});
											reject({
												status: "error",
												message: `${file.name} is not a PDF file. Please upload a valid PDF file.`,
											});
										} else if (!isLt5M) {
											notification.error({
												message: file.name,
												description: `${file.name} is larger than 5 MB. Please upload a file smaller than 5 MB.`,
											});
											reject({
												status: "error",
												message: `${file.name} is larger than 5 MB. Please upload a file smaller than 5 MB.`,
											});
										} else {
											resolve({
												...item,
												status: "success",
											});
										}
									});
								});

								let promise = Promise.all(
									newFileListPromises.map((p) => p.catch((e) => e))
								).then((files) => files.filter((f) => f.status === "success"));

								promise.then((files) => {
									setEmailAttachment(files);
								});
							}}
							beforeUpload={(file) => {
								return false;
							}}
						>
							Upload A Maximum of 3 PDF Files (5MB each) for email attachments
						</Upload.Dragger>

						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<div className="event-email-attachment-upload__preview">
								{emailAttachment.map((item, index) => {
									return (
										<div key={index} className="event-email-attachment">
											<Flex justify="space-between">
												<Flex align="center" gap={10}>
													<Button
														type="link"
														onClick={() => {
															window.open(
																item.src
																	? item.src
																	: URL.createObjectURL(item.originFileObj)
															);
														}}
													>
														<FontAwesomeIcon icon={faBrowser} />
													</Button>
													<div className="name">{item.name}</div>
												</Flex>

												<Button
													onClick={() => handleDeleteAttachment(item, index)}
													className="btn-delete"
													type="link"
													loading={isLoadingECOMAttachment}
												>
													<FontAwesomeIcon icon={faTrash} />
												</Button>
											</Flex>
										</div>
									);
								})}
							</div>
						</Col>

						<Progress
							percent={onProgress}
							status="active"
							strokeColor={{
								from: "#108ee9",
								to: "#87d068",
							}}
						/>
					</Col>

					{location.pathname.includes("received") && (
						<>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Form.Item
									name="note"
									className="mt-20 mb-0"
									rules={[validateRules.required]}
								>
									<FloatTextArea label="Note" placeholder="Note" allowClear />
								</Form.Item>
							</Col>

							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Form.Item
									name="attach_signature"
									rules={[validateRules.required]}
									initialValue={false}
									valuePropName="checked"
								>
									<Checkbox>Attach signature</Checkbox>
								</Form.Item>
							</Col>
						</>
					)}
				</Row>
			</Form>
		</Modal>
	);
}
