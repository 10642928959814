import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Button, Empty, Flex, Tooltip } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function ModalFilePreview(props) {
	const { toggleModalFilePreview, setToggleModalFilePreview } = props;

	const location = useLocation();
	const [numPages, setNumPages] = useState();
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = (e) => {
		setNumPages(e.numPages);
	};

	const [pdfAttachment, setPdfAttachment] = useState(null);
	const [pdfAttachments, setPdfAttachments] = useState([]);

	useEffect(() => {
		console.log("toggleModalFilePreview", toggleModalFilePreview);

		if (toggleModalFilePreview.data) {
			setPdfAttachments(toggleModalFilePreview.data.attachments);
			if (toggleModalFilePreview.data.attachments.length > 0) {
				setPdfAttachment(toggleModalFilePreview.data.attachments[0].pdf);
			}
		}
		// convert to pdf

		return () => {};
	}, [toggleModalFilePreview]);

	return (
		<Modal
			title="File Preview"
			wrapClassName="modal-wrap-upload-file-preview"
			open={toggleModalFilePreview.open}
			onCancel={() => {
				setToggleModalFilePreview({
					open: false,
					data: null,
				});
			}}
			footer={null}
		>
			{pdfAttachment ? (
				<>
					<Document file={pdfAttachment} onLoadSuccess={onDocumentLoadSuccess}>
						<Page pageNumber={pageNumber} renderTextLayer={false} />
					</Document>

					<div className="action">
						<Button
							onClick={() => setPageNumber((ps) => (ps > 1 ? ps - 1 : 1))}
							className={pageNumber > 1 ? "btn-main-primary" : ""}
						>
							PREV
						</Button>
						<Button
							onClick={() =>
								setPageNumber((ps) => (ps < numPages ? ps + 1 : numPages))
							}
							className={numPages !== pageNumber ? "btn-main-primary" : ""}
						>
							NEXT
						</Button>
					</div>
				</>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No File Pdf" />
			)}

			<Flex justify="center" gap={10} className="mt-10">
				{pdfAttachments.map((item, index) => (
					<Tooltip title={item.file_name} key={index}>
						<Button
							onClick={() => setPdfAttachment(item.pdf)}
							className={pdfAttachment === item.pdf ? "active-file" : ""}
						>
							{index + 1}.{" "}
							{item.file_name.length > 10
								? item.file_name.substring(0, 10) + "..."
								: item.file_name}
						</Button>
					</Tooltip>
				))}
			</Flex>
		</Modal>
	);
}
